<template lang="pug">
.list-page(:class="[lang]")
  div.header
    div.logo_area(@click="backHome")
      div.img_area
        img(src="../../assets/img/m/logo.png")
      div.title Answered!
    .area-right
      div.lanSwitch
        el-dropdown(@command="handleCommand", trigger="click")
          span.el-dropdown-link {{command}}
              i.el-icon-arrow-down.el-icon--right
          el-dropdown-menu(slot="dropdown")
              el-dropdown-item(command="Eng") Eng
              el-dropdown-item(command="Indonesia") Indonesia
      .input-area(v-if="isShowInput")
        input.input(
          :placeholder="$TT('search')"
          maxlength='150'
          v-model="inputValue"
          autofocus="autofocus"
          @keyup.enter="unShowInput('listpage_search_enter')"
        )
        img.input-search_icon(src="../../assets/img/m/input-icon.png")
      .search-icon(@click="showInput" v-else)
        img(src="../../assets/img/m/search-icon.png")
  .section
    .problems-list
      .title(v-html="$TT('problems')")
      .cut-line
      .no-problems(v-if="isNoProblems")
        .no-porblems_img
          img(src="../../assets/img/noProblems.png")
        .no-problems_text(v-html="$TT('noProblems')")
      .scroll(v-infinite-scroll="getOnQuestionList" :infinite-scroll-disabled="loading" infinite-scroll-distance="10")
        .problems-content#problems
          .problems-item(v-for='(item, index) in questionList' :key='index')
            .item_detail(v-html='item.question')
            .check-solution(@click="viewDetails(item, 'listpage_checksolution_click')" v-html="$TT('Check solution')")
              .icon
          .bottom#bottom
      .loading(v-if='loading')
        img(src="../../assets/img/loading.gif")
  div.footer(@click="download('listpage_download_foot_click')")
    div.logo
      img(src="../../assets/img/m/footer_logo.png")
    div.title(v-html="$TT('mforfree')")
    div.google
      img(src="../../assets/img/m/google.png")
</template>
<script>
import service from '@/service/index.js'
import MathJax from '@/util/mathJax.js'
import { mapState } from "vuex";

export default {
  data() {
    return {
      lang:"en",
      command:'Eng',
      isShowInput: false,
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
      inputValue: '',
      size: 15,
      tag: '',
      questionList: [],
      timeId: null,
      isNoProblems: false,
    }
  },
  created() {
    this.lang = this.$route.query.lang || 'en'
    this.$setLang(this.lang)
    window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
    window.gtag('event', 'listpage_show')
  },
  mounted() {
    this.inputValue = this.$route.query.inputValue || ''
    this.getQuestionList()
  },
  beforeRouteUpdate (to, from, next) {
    if(to.fullPath === from.fullPath) {
      return
    } else {
      this.getQuestionList()
    }
    next()
  },
  computed: {
    country: function() {
      if(this.lang === 'en') {
        return 'EN'
      } else {
        return 'ID'
      }
    },
    ...mapState([
        'loading'
    ])
  },
  watch:{
    command:function(){
        if(this.command == 'Eng' && this.lang !== 'en'){
          this.lang = 'en'
          this.$setLang(this.lang)
          this.unShowInput('listpage_search_enter')
        }else if(this.command == 'Indonesia' && this.lang !== 'id') {
          this.lang = 'id'
          this.$setLang(this.lang)
          this.unShowInput('listpage_search_enter')
        }
    },
    lang:function(){
      window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
      window.gtag('event', 'language_switch')
      if(this.lang == 'en'){
          this.command = 'Eng'
      }else if(this.lang == "id"){
        this.command = "Indonesia"
      }
    },
  },
  methods: {
    backHome() {
      this.$router.push({
        path: '/m',
        query: {
          lang: this.lang
        }
      })
    },
    handleCommand(command){
        this.command = command
    },
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    formatMath() {
      let that = this;
      if(this.timeId) {
        clearTimeout(this.timeId)
      }
      this.timeId = setTimeout(function () {
        that.$nextTick(function () {
          if(MathJax.initMathjaxConfig()){//判断是否初始配置，若无则配置。
            MathJax.MathQueue('problems');
          }
        })
      },10);
    },
    viewDetails(item, eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/m/quesDetails',
        query: {
          lang: this.lang,
          id: item.id,
        }
      })
    },
    showInput() {
      this.isShowInput = true
    },
    unShowInput(eventType) {
      if(this.inputValue === '') {
        this.isShowInput = false
      } else {
        window.gtag('event', eventType)
        this.$router.push({
          path: '/m/quesList',
          query: {
            lang: this.lang,
            inputValue: this.inputValue
          }
        })
      }
    },
    getOnQuestionList() {
      if(this.loading) {
        return
      }
      this.$store.commit('showLoading')
      const formData = new FormData()
      formData.append('text', this.inputValue)
      formData.append('country', 'ID')
      formData.append('size', this.size)
      formData.append('tag', this.tag)
      service.searchQuestion(formData).then((res) => {
        this.$store.commit('hideLoading')
        this.questionList.push(...res.data.rows)
        this.tag = res.data.tag
        this.formatMath();
      })
    },
    getQuestionList() {
      if(this.loading) {
        return
      }
      this.$store.commit('showLoading')
      this.tag = ''
      const formData = new FormData()
      formData.append('text', this.inputValue)
      formData.append('country', 'ID')
      formData.append('size', this.size)
      formData.append('tag', this.tag)
      service.searchQuestion(formData).then((res) => {
        this.$store.commit('hideLoading')
        if(res.data.rows.length === 0) {
          this.isNoProblems = true
        } else {
          this.isNoProblems = false
        }
        this.questionList = res.data.rows
        this.tag = res.data.tag
        this.formatMath();
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.list-page
  font-family: Roboto;
.header
  display:flex
  height 1.44rem
  align-items:center
  padding: 0 0.4rem
  position:relative
  background #FFFFFF
  box-shadow: 0 0.04rem 0.4rem rgba(0, 0, 0, 0.1)
  .logo_area
    display:flex
    align-items:center
    cursor pointer
    .img_area
      width:0.64rem
      height 0.64rem
      margin-right:0.16rem
      img
        width:100%
    .title
      font-weight: bold;
      font-size: 0.26rem;
      color: #111111;
  .area-right
    position absolute
    right 0.4rem
    .lanSwitch
      display inline-block
      vertical-align middle
      margin-right 0.18rem
      .el-dropdown-link
        font-size: 0.26rem;
        color: #1E1E20;
    .search-icon
      width 0.325rem
      height 0.325rem
      display inline-block
      vertical-align middle
      img
        width 100%
    .input-area
      position relative
      display inline-block
      vertical-align middle
      .input
        background #F3F3F3
        border-radius 0.12rem
        outline none
        font-family:Roboto-Regular;
        font-size 0.26rem
        width 2.28rem
        height 0.8rem
        line-height 0.3rem
        color #A0A0A0
        padding-left 0.53rem
      .input-search_icon
        width 0.24rem
        height 0.24rem
        position absolute
        top 0.28rem
        left 0.2025rem
.section
  background #F8FAFD
  min-height calc( 100vh - 1.44rem)
  overflow hidden
  .problems-list
    margin 0.4rem 0.4rem 1.7rem
    background #FFFFFF
    overflow hidden
    .no-problems
      .no-porblems_img
        margin 0.8rem auto 0.32rem
        width 1.4rem
        height 1.06rem
        img
          width 100%
       .no-problems_text
        width 2rem
        margin 0 auto 0.9rem
        font-family Roboto-Regular
        font-size 0.18rem
        line-height 0.27rem
        letter-spacing 0.001rem
        color #111100
        text-align center
    .title
      margin 0.3rem 0 0.3rem 0.4rem
      height 0.44rem
      font-size 0.32rem
      line-height 0.44rem
      font-family Roboto-Black
      color #111100
    .cut-line
      height 0.01rem
      background #DDDDDD
    .loading
      margin 0 0.4rem
      background #FFF
      img
        width 1.4rem
        height 1rem
        margin-left calc(50% - 0.7rem)
    .problems-content
      overflow hidden
      .problems-item
        margin 0.4rem 0.4rem 0
        border-bottom 0.01rem solid #E8E8E8
        .check-solution
          width 1.93rem
          height 0.48rem
          background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
          border-radius 0.08rem
          font-size 0.21rem
          line-height 0.48rem
          color #FF6B08
          text-align center
          font-weight 500
          margin 0.32rem 0 0.4rem
          .icon
            width 0.1rem
            height 0.1rem
            box-sizing border-box
            font-size 0
            display inline-block
            background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
            border 0.02rem solid #FF6B08
            border-top 0
            border-left 0
            transform rotate(-45deg)
.footer
  overflow hidden
  width 100%
  height:1.3rem
  position fixed
  bottom 0
  background url(../../assets/img/m/footerbg.png) 0 0 / 100%
  .logo
    width:0.6rem
    height:0.6rem
    display inline-block
    position absolute
    top 0.35rem
    left 0.6rem
    border:0.001rem soild #FFFFFF
    img
      width:100%
  .title
    width:3.8rem
    font-family:Roboto-Black;
    display inline-block
    font-weight: 900;
    font-size: 0.3rem;
    line-height: 0.35rem;
    text-align: left;
    color: #FFFFFF;
    position absolute
    top 0.33rem
    left 1.41rem
    span
      color:#FF6B08;
  .google
    width 1.66rem
    height 0.4919rem
    display inline-block
    position absolute
    top 0.4rem
    right 0.6rem
    img
      width:100%
</style>
